import React, { ReactElement } from 'react'
import LoginStatus from './account/LoginStatus'
import { isPartner, isManager, isAgentAnyCompany, isEditor, isAdmin, canViewAllPrograms, isEditorAnyCourse2, canViewBundles, canViewPartners, canViewAllCompanies, canViewAllUsers, isSubscriptionExpired, isManagerAnyOrganization, isSuperEditor, isBillingManager, isAgent } from 'lib/acl_logic'
import { UserAuthenticated } from '../models/user'
import { withAuthUser } from './AuthUserWrapper'
import i18n from 'components/i18n'
import ContextMenu from './ContextMenu'
import clsx from 'clsx'
import { createTheme } from '@material-ui/core/styles'
import { ownTheme } from './Layout'
import { updateMetaTour } from '../lib/user_metadata'
import { Button } from 'components/elements/Button'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GetOrganizationRes, GET_ORGANIZATION } from 'queries/organization'
import { NewFeatureBadge, NewFeatureRedDot } from './NewFeatureBadge'
import CLIENT_SETTINGS from 'lib/client_settings'
import WelcomeMenuItem from './WelcomeMenuItem'
import FEATURES from 'utils/features'
import { showExipredSubscription } from './snackbar'
import { useMatch, useNavigate } from 'react-router-dom'
import MenuIcon from './utils/Icons/MenuIcon'
import CloseIcon from './utils/Icons/CloseIcon'
import OrganizationIcon from './utils/Icons/OrganizationIcon'
import GroupsIcon from './utils/Icons/GroupsIcon'
import SettingsIcon from './utils/Icons/SettingsIcon'
import ProgramsIcon from './utils/Icons/ProgramsIcon'
import MyProgramsIcon from './utils/Icons/MyProgramsIcon'
import MidServicesIcon from './utils/Icons/MidServicesIcon'
import MlcGnowbeIcon from './utils/Icons/MlcIcon'
import Tippy from '@tippyjs/react'
import BundlesIcon from './utils/Icons/BundlesIcon'
import SalesIcon from './utils/Icons/SalesIcon'
import PromoCodesIcon from './utils/Icons/PromoCodesIcon'
import LibraryIcon from './utils/Icons/LibraryIcon'
import ExternalRefIcon from './utils/Icons/ExternalRefIcon'
import PartnersIcon from './utils/Icons/PartnersIcon'
import UsersIcon from './utils/Icons/UsersIcon'
import RunnerIcon from './utils/Icons/RunnerIcon'
import FlagIcon from './utils/Icons/FlagIcon'
import BannerIcon from './utils/Icons/BannerIcon'
import { getOrganizationProductDescription, isFreetierOrCreator } from 'models/organization'
import { track } from 'segment'


type LeftDrawerProps = {
  userAuthenticated?: UserAuthenticated|null;
  setThemetmp: (t: object) => void
}

const logoBarStyle = {
  backgroundColor: 'white',
}

type LeftDrawerItemLinkProps = {
  onClick: () => void;
  icon: ReactElement;
  text?: string;
  hiddenText: string;
  tooltipText: string;
  component?: React.ReactNode;
}

const LeftDrawerItemLink = (props: LeftDrawerItemLinkProps) => {
  return (
    <a onClick={props.onClick} className="mt-1 group flex items-center py-2 text-base leading-5 font-medium text-medgray rounded-md hover:text-deepgray hover:bg-gray-100 focus:outline-none focus:text-deepgray focus:bg-gray-100 transition ease-in-out duration-150">
      <Tippy content={props.tooltipText}>
        <div>{props.icon}</div>
      </Tippy>
      {props.text && <span className={props.hiddenText}>
        {props.text}
      </span>}
      {props.component && <div className={props.hiddenText}>{props.component}</div>}
    </a>
  )
}

// tslint:disable-next-line: function-name
function LeftDrawerItems(props: LeftDrawerProps) {
  const navigate = useNavigate()
  const match = useMatch('organization/:organizationId/*')
  const _timeout = React.useRef<any>(null)
  const [logoBarStyletmp, setLogoBarStyletmp] = React.useState(logoBarStyle)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [pinned, setPinned] = React.useState(false)

  const organizationId = match?.params.organizationId
  const {data, loading, error} = useQuery<GetOrganizationRes>(GET_ORGANIZATION(['with_settings']), {
    variables: {
      organizationId,
    },
    skip: !organizationId,
  })

  React.useEffect(
    () => {
      if (pinned  && !openDrawer) {
        setOpenDrawer(true)
      }
    },
    [pinned],
  )

  const handleLeftNavClick = (data: string) => {
    navigate(data)
  }

  const handleDrawer = () => {
    setPinned(!pinned)
  }

  const handleEnterDrawerMouse = (event: any) => {
    if (openDrawer) return
    _timeout.current = setTimeout(() => setOpenDrawer(true), 500)
  }

  const handleMouseLeave = (event: any) => {
    if (!_timeout.current && (!openDrawer || pinned)) return
    _timeout.current = clearTimeout(_timeout.current)
    setOpenDrawer(false)
  }

  const handleMenuIconMouseEnter = (event: any) => {
    if (!!_timeout.current) {
      _timeout.current = clearTimeout(_timeout.current)
    }
  }

  const organization = data?.organization || null
  const checkHidden = clsx({
    hidden: !openDrawer, 'whitespace-nowrap': true,
  })
  const iconItemStyle = 'fill-listenblue mx-3'
  const checkNotAllowed =  clsx({
    'text-lightgray italic cursor-not-allowed': !!organizationId && isSubscriptionExpired(organization),
  })
  const linkCn = clsx({
    'mt-1 group flex items-center py-2 text-base leading-6 font-medium text-medgray rounded-md hover:text-deepgray hover:bg-gray-100 focus:outline-none focus:text-deepgray focus:bg-gray-100 transition ease-in-out duration-150': true,
  })


  return (
    <div
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleEnterDrawerMouse}
      className={`${clsx({'w-64 shadow-2xl lg:shadow-none': openDrawer, 'w-16': !openDrawer})} transition-all fixed flex flex-col h-screen border-r lg:relative border-gray-200 z-10 top-0 left-0 overflow-hidden whitespace-nowrap bg-white`}
    >
      <div
        style={{minHeight: 70}}
        className="w-full border-b flex justify-between items-center"
      >
        <div onClick={() => navigate('/')} className={`${clsx([{hidden: !openDrawer}])} ml-3 hover:cursor-pointer flex items-center justify-center w-12 h-12 rounded-full my-3`}>
          <LogoComponent
            organizationId={organizationId}
            changeTheme={(t, a) => {
              props.setThemetmp(t)
              setLogoBarStyletmp(a)
            }}
          />
        </div>
        {/* need unpin_sidebar from i18n*/}
        <Button
          type="text"
          onMouseEnter={handleMenuIconMouseEnter}
          onClick={(e) => {
            e.preventDefault()
            handleDrawer()
          }}
          icon={
            pinned
          ? <CloseIcon
              className="text-listenblue ml-3 mr-3"
              w={22}
              h={22}
              />
          : <div>
              <Tippy content={`${i18n.t('pin_sidebar')}`}>
                <div className="w-full flex flex-row justify-end items-center">
                  <MenuIcon
                    className="text-listenblue"
                    w={22}
                    h={22}
                  />
                </div>
              </Tippy>
            </div>
        }/>
      </div>

      <div className="w-full" data-test="organization-swithcer">
        <LoginStatus
          organizationId={organizationId || ''}
          openDrawer={openDrawer}
        />
      </div>

      {/* Crosslink Web */}
      <ContextMenu
        cmp={
          <a href={`${CLIENT_SETTINGS.public.gnowbeWebUrl}/home`} target='_blank' className="text-base leading-5 font-medium text-medgray hover:text-deepgray focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
            <Tippy content="Back to Gnowbe Web">
              <div className="bg-[#F9F7F2] h-[45px] border-l-4 border-solid border-coral px-1 py-3 w-full group flex flex-row items-center flex-nowrap">
                <img src={'/images/gnowbe-coral-logomark.png'} width={22} height={22} className={iconItemStyle} />
                <span className={`${checkHidden}`}>
                  Gnowbe Web
                </span>
              </div>
            </Tippy>
          </a>
        }
        route={`${CLIENT_SETTINGS.public.gnowbeWebUrl}/home`}
      />
      <div className={`${clsx([{'overflow-y-auto': openDrawer}])} overflow-hidden px-2 border-t border-solid pt-2 pb-[100px] border-[#EEE8D9]`}>
        {/* HOME */}
        <ContextMenu
          cmp={<WelcomeMenuItem openDrawer={openDrawer} />}
          route="/"
        />
        {isSuperEditor(props.userAuthenticated) && (
          <ContextMenu
            cmp={
              <LeftDrawerItemLink
                onClick={() => handleLeftNavClick('/banners')}
                icon={<BannerIcon className="fill-listenblue mx-3" w={22} h={22}/>}
                hiddenText={checkHidden}
                text="Banner creation"
                tooltipText="Create your banner"
              />
            }
            route="/banners"
          />
        )}
        {/* PARTNER */}
        { isPartner(props.userAuthenticated) && <>
            <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
              {
                openDrawer
                ? <span>{`${i18n.t('partner')}`}</span>
                : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
              }
            </div>
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/organizations')}
                  icon={<OrganizationIcon className="fill-listenblue mx-3" w={22} h={22}/>}
                  hiddenText={checkHidden}
                  text={i18n.t('organization', {count: 0})}
                  tooltipText={`${i18n.t('organization', {count: 0})}`}
                />
              }
              route="/organizations"
            />

          </>
        }
        {/* MANAGER LINKS */}
        { (organizationId
            && (isManager(props.userAuthenticated, organizationId)
            || isAgentAnyCompany(props.userAuthenticated))
          ) && <>
            <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
              {
                openDrawer
                ? <span>{`${i18n.t('organization')}`}</span>
                : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
              }
            </div>
            <ContextMenu
              disabled={!!organizationId && isSubscriptionExpired(organization)}
              cmp={
                <LeftDrawerItemLink
                  onClick={() => {
                    if (!!organizationId && isSubscriptionExpired(organization)) {
                      const expiredModal = showExipredSubscription(() => {
                        expiredModal.close()
                        if (organization) {
                          navigate(`/organization/${organization?.id}?scrollTo=settings-billing-details&upgrade=true#settings`)
                        }
                      })
                      return
                    }
                    handleLeftNavClick(`/organization/${organizationId}#groups`)
                  }}
                  icon={<GroupsIcon className={`${iconItemStyle} ${checkNotAllowed}`} w={22} h={22} />}
                  hiddenText={`${checkHidden} ${checkNotAllowed}`}
                  text={i18n.t('group', {count: 0})}
                  tooltipText={`${i18n.t('group', {count: 0})}`}
                />
              }
              route={`/organization/${organizationId}#groups`}
            />
            {organization && isFreetierOrCreator(organization?.product)
              && (isManager(props.userAuthenticated, organizationId)
                || isBillingManager(props.userAuthenticated, organizationId)
                || isAgentAnyCompany(props.userAuthenticated))
              && (
              <ContextMenu
                cmp={
                  <LeftDrawerItemLink
                    onClick={() => handleLeftNavClick(`/referral/${organizationId}`)}
                    icon={<svg width="21" height="21" viewBox="0 0 21 21" className="fill-listenblue mx-3" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5 8.00001C11.5 6.35001 10.15 5.00001 8.49999 5.00001C6.84999 5.00001 5.49999 6.35001 5.49999 8.00001C5.49999 9.65001 6.84999 11 8.49999 11C10.15 11 11.5 9.65001 11.5 8.00001ZM8.49999 9.00001C7.94999 9.00001 7.49999 8.55001 7.49999 8.00001C7.49999 7.45001 7.94999 7.00001 8.49999 7.00001C9.04999 7.00001 9.49999 7.45001 9.49999 8.00001C9.49999 8.55001 9.04999 9.00001 8.49999 9.00001ZM15 11C16.11 11 17 10.11 17 9.00001C17 7.89001 16.11 7.00001 15 7.00001C13.89 7.00001 12.99 7.89001 13 9.00001C13 10.11 13.89 11 15 11ZM10.99 0.0100098C5.46999 0.0100098 0.98999 4.49001 0.98999 10.01C0.98999 15.53 5.46999 20.01 10.99 20.01C16.51 20.01 20.99 15.53 20.99 10.01C20.99 4.49001 16.51 0.0100098 10.99 0.0100098ZM4.83999 15.12C5.51999 14.58 7.10999 14.01 8.49999 14.01C8.56999 14.01 8.64999 14.02 8.72999 14.02C8.96999 13.38 9.39999 12.73 10.03 12.16C9.46999 12.06 8.93999 12 8.49999 12C7.19999 12 5.10999 12.45 3.76999 13.43C3.26999 12.39 2.98999 11.23 2.98999 10C2.98999 5.59001 6.57999 2.00001 10.99 2.00001C15.4 2.00001 18.99 5.59001 18.99 10C18.99 11.2 18.72 12.34 18.24 13.37C17.24 12.78 15.88 12.5 15 12.5C13.48 12.5 10.5 13.31 10.5 15.2V17.98C8.22999 17.85 6.20999 16.77 4.83999 15.12Z" fill="#1B585E"/>
                    </svg>
                    }
                    hiddenText={checkHidden}
                    tooltipText={i18n.t('referral')}
                    text={i18n.t('referral')}
                  />
                }
                route={`/referral/${organizationId}`}
              />
            )}
            {isManager(props.userAuthenticated, organizationId) && (
              <ContextMenu
                cmp={
                  <LeftDrawerItemLink
                    onClick={() => handleLeftNavClick(`/organization/${organizationId}#settings`)}
                    icon={<SettingsIcon className={iconItemStyle} w={22} h={22} />}
                    hiddenText={checkHidden}
                    tooltipText={i18n.t('setting', {count: 0})}
                    text={i18n.t('setting', {count: 0})}
                  />
                }
                route={`/organization/${organizationId}#settings`}
              />
            )}
          </>
        }
        {/* PROGRAM LINKS */}
        { isEditor(props.userAuthenticated) && <>
          <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
            {
              openDrawer
              ? <span>{`${i18n.t('curator')}`}</span>
              : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
            }
          </div>
          { canViewAllPrograms(props.userAuthenticated) && <ContextMenu
            cmp={
              <LeftDrawerItemLink
                onClick={() => handleLeftNavClick('/programs')}
                icon={<ProgramsIcon className={iconItemStyle} w={22} h={22} /> }
                hiddenText={checkHidden}
                text={i18n.t('all_programs')}
                tooltipText={i18n.t('all_programs')}
              />
            }
            route="/programs"
            />
          }
          { isEditorAnyCourse2(props.userAuthenticated) && <ContextMenu
              disabled={!!organizationId && isSubscriptionExpired(organization)}
              cmp={<LeftDrawerItemLink
                onClick={() => handleLeftNavClick('/curator/programs')}
                icon={<MyProgramsIcon className={iconItemStyle} w={22} h={22} />}
                hiddenText={checkHidden}
                text={i18n.t('my_programs')}
                tooltipText={i18n.t('my_programs')}
              />}
              route="/curator/programs"
            />
          }
        </>
        }
        {/** Services */}
        {(isAgentAnyCompany(props.userAuthenticated) || isManagerAnyOrganization(props.userAuthenticated)) && (
          <>
            <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
              {
                openDrawer
                ? <span>Services</span>
                : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
              }
            </div>
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  // linkDataTut="mid-services"
                  onClick={() => {
                    track({event: 'Curation Services Visited', variables: {
                      workspaceId: organization?.id,
                      platform: 'dashboard',
                      ...(organization
                        ? {
                          workspaceName: organization.name,
                          workspaceProductId: organization.product,
                          workspaceProductName: getOrganizationProductDescription(organization.product),
                          workspacePlanId: organization.billingService?.planId,
                          workspacePlanName: organization.billingService?.planName,
                        }
                        : undefined
                      ),
                    }})
                    handleLeftNavClick('/services/mid_services')
                  }}
                  icon={<>
                    <MidServicesIcon className={iconItemStyle} w={22} h={22} />
                  </>}
                  hiddenText={checkHidden}
                  tooltipText="Curation Services"
                  text={i18n.t('Curation Services')}
                />
              }
              route="/services/mid_services"
            />
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  // linkDataTut="service-mlcs"
                  onClick={() => {
                    track({event: 'Gnowbe MLCs Visited', variables: {
                      workspaceId: organization?.id,
                      platform: 'dashboard',
                      ...(organization
                        ? {
                          workspaceName: organization.name,
                          workspaceProductId: organization.product,
                          workspaceProductName: getOrganizationProductDescription(organization.product),
                          workspacePlanId: organization.billingService?.planId,
                          workspacePlanName: organization.billingService?.planName,
                        }
                        : undefined
                      ),
                    }})
                    handleLeftNavClick('/services/gnowbe_mlcs')
                  }}
                  icon={<>
                    <MlcGnowbeIcon className={iconItemStyle} w={22} h={22}/>
                  </>}
                  hiddenText={checkHidden}
                  text = "Content Library"
                  tooltipText="Content Library"
                />
              }
              route="/services/gnowbe_mlcs"
            />
          </>)
        }

        {/* GnowbeLearn */}
        { canViewBundles(props.userAuthenticated) && <>
            <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
              {
                openDrawer
                ? <span>GnowbeLearn</span>
                : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
              }
            </div>
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/learn/bundles')}
                  icon={<BundlesIcon className={iconItemStyle} w={22} h={22} />}
                  hiddenText={checkHidden}
                  text="Bundles"
                  tooltipText="Bundles"
                />
              }
              route="/learn/bundles"
            />
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/learn/sales')}
                  icon={<SalesIcon w={22} h={22} className={iconItemStyle} />}
                  hiddenText={checkHidden}
                  text={i18n.t('sale_plural')}
                  tooltipText={i18n.t('sale_plural')}
                />
              }
              route="/learn/sales"
            />
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/learn/promo_codes')}
                  tooltipText={`${i18n.t('promo_code', {count: 0})}`}
                  icon={<PromoCodesIcon className={iconItemStyle} w={22} h={22} />}
                  hiddenText={checkHidden}
                  text={i18n.t('promo_code', {count: 0})}
                />
              }
              route="/learn/promo_codes"
            />
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/course_libraries')}
                  icon={<LibraryIcon h={22} w={22} className={iconItemStyle}/>}
                  hiddenText={checkHidden}
                  text={i18n.t('course_libraries')}
                  tooltipText={i18n.t('course_libraries')}
                />
              }
              route="/course_libraries"
            />
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/bundle_ext_ref_gen')}
                  tooltipText="Ext Ref"
                  icon={<ExternalRefIcon className={iconItemStyle} w={22} h={22}/>}
                  hiddenText={checkHidden}
                  text="Ext Ref"
                />
              }
              route="/bundle_ext_ref_gen"
            />
          </>
        }

        {/* GnowbeShop */}
        { canViewBundles(props.userAuthenticated) && <>
            <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
              {
                openDrawer
                ? <span>GnowbeShop</span>
                : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
              }
            </div>
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/shop/sales')}
                  tooltipText={`${i18n.t('sale', {count: 0})}`}
                  icon={<SalesIcon w={22} h={22} className={iconItemStyle} />}
                  hiddenText={checkHidden}
                  text="Sales"
                />
              }
              route="/shop/sales"
            />
          </>
        }

        {/* INTERNAL */}
        {
          (canViewPartners(props.userAuthenticated) ||
          canViewAllCompanies(props.userAuthenticated) ||
          canViewAllUsers(props.userAuthenticated)
          ) ?
          <>
            <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
              {
                openDrawer
                ? <span>Gnowbe Internal</span>
                : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
              }
            </div>
            {canViewPartners(props.userAuthenticated) ?
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/partners')}
                  tooltipText="Partners"
                  icon={<PartnersIcon w={22} h={22} className={iconItemStyle} />}
                  hiddenText={checkHidden}
                  text="Partners"
                />
              }
              route="/partners"
            />
            : undefined}
            {canViewAllCompanies(props.userAuthenticated) ?
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/companies')}
                  tooltipText="Groups"
                  icon={<GroupsIcon className={`${iconItemStyle} ${checkNotAllowed}`} h={22} w={22} />}
                  hiddenText={checkHidden}
                  text="Groups"
                />}
              route="/companies"
            />
            : undefined}
            {canViewAllUsers(props.userAuthenticated) ?
            <ContextMenu
              cmp={
                <LeftDrawerItemLink
                  onClick={() => handleLeftNavClick('/users')}
                  tooltipText="Users"
                  icon={<UsersIcon className={iconItemStyle} w={22} h={22} />}
                  hiddenText={checkHidden}
                  text="Users"
                />
              }
              route="/users"
            />
            : undefined}
          </>
          : undefined
        }

        {/* ADMIN */}
        { isAdmin(props.userAuthenticated) && <>
          <div className="ml-3 h-4 mt-3 flex flex-row items-end font-bold text-sm">
            {
              openDrawer
              ? <span>Admin</span>
              : <div className=" w-6 h-1 rounded-full bg-gray-200"></div>
            }
          </div>
          <ContextMenu
            cmp={
              <LeftDrawerItemLink
                onClick={() => handleLeftNavClick('/admin/job_runner')}
                icon={<RunnerIcon className={iconItemStyle} w={22} h={22} />}
                hiddenText={checkHidden}
                tooltipText="Job Runner"
                text="Job Runner"
              />
            }
            route="/admin/job_runner"
          />
          <ContextMenu
            cmp={
              <LeftDrawerItemLink
                onClick={() => handleLeftNavClick('/admin/redis_streams/topics')}
                icon={<RunnerIcon className={iconItemStyle} h={22} w={22} />}
                hiddenText={`${clsx({hidden: !openDrawer, block: openDrawer})}`}
                text="Redis Streams"
                tooltipText="Redis Streams"
              />
            }
            route="/admin/redis_streams/topics"
          />
          <ContextMenu
            cmp={
              <LeftDrawerItemLink
                onClick={() => handleLeftNavClick('/admin/course_delete_confirmations')}
                icon={<RunnerIcon className={iconItemStyle} h={22} w={22} />}
                hiddenText={`${checkHidden} truncate`}
                text="Course Delete Confirmations"
                tooltipText="Course Delete Confirmations"
              />
            }
            route="/admin/course_delete_confirmations"
          />
          <ContextMenu
            cmp={
              <LeftDrawerItemLink
                onClick={() => handleLeftNavClick('/admin/user_delete_confirmations')}
                icon={<RunnerIcon className={iconItemStyle} w={22} h={22}/>}
                hiddenText={`${checkHidden} truncate`}
                text="User Delete Confirmations"
                tooltipText="User Delete Confirmations"
              />
            }
            route="/admin/user_delete_confirmations"
          />
        </>
        }
      </div>
      <div className="text-center bottom-0 absolute bg-white w-full flex flex-row items-center justify-center py-4">
        {!openDrawer
          ? <FlagIcon
              className="ml-3 mr-3 w-[20px] h-[20px]"
              isOrganization={organizationId ? true : false}
            />
          : <div className="flex flex-col items-center justify-center">
          Missed a tip?
          <Tippy
            // className="h-6 p-auto text-[10px] bg-gray-500" arrow="none" placement="top" animation="scale-subtle"
            disabled={organizationId ? true : false}
            placement="top"
            content={'Guide is not available for internal / admin pages'}
          >
            <div>
              <Button disabled={!organizationId} className="underline hover:shadow-none text-listenblue"
              text="Restart the dashboard tour." size="small" type="text" onClick={() => {
                if (organizationId) {
                  return navigate(`/organization/${organizationId}?showMainGuide=true`, {replace: true})
                }
                updateMetaTour('tour/mainDashboardTour', 'false')
                return navigate('/')
              }} />
            </div>
          </Tippy>
        </div>}
      </div>
    </div>
  )
}

export default withAuthUser(LeftDrawerItems)

type CustomPalette = {
  dashboardLogo?: string|null;
  appBarBackgroundColor?: string|null;
  appBarColor?: string|null;
  primaryColor?: string|null;
  secondaryColor?: string|null;
}|null

type WhiteLabelType = { id: string, customPalette: CustomPalette; }
const LogoComponent = ({ organizationId, changeTheme }: { organizationId?: string, changeTheme: (t, a) => void }) => {
  const gnowbeLogo = <img
    src="/images/gnowbe-sidebar-logo.png"
    className="rounded-full w-full h-full object-contain bg-white border border-solid border-[#EEE8D9]"
  />
  const [loadOrganization, {
    data,
    loading,
    error,
  }] = useLazyQuery<{ organization: WhiteLabelType}, { organizationId: string }>(GET_ORGANIZATION(['with_styles']))

  React.useEffect(
    () => {
      if (!organizationId) return
      loadOrganization({ variables: { organizationId }})
    },
    [organizationId],
  )

  React.useEffect(
    () => {
      let customPalette
      let customAppBar
      if (!organizationId || !data?.organization || loading) {
        customPalette = createTheme(ownTheme)
        customAppBar = logoBarStyle
      } else {
        customPalette = createTheme({
          ...ownTheme,
          palette: {
            primary: {
              main: data.organization.customPalette
                && data.organization.customPalette.primaryColor
                || ownTheme.palette.primary.main,
            },
            secondary: {
              main: data.organization.customPalette
                && data.organization.customPalette.secondaryColor
                || ownTheme.palette.secondary.main,
            },
          },
        })
        customAppBar = {
          ...logoBarStyle,
          backgroundColor: data.organization.customPalette
            && data.organization.customPalette.appBarBackgroundColor
            || logoBarStyle.backgroundColor,
        }
      }
      changeTheme(customPalette, customAppBar)
    },
    [organizationId, data, loading],
  )

  if (!organizationId
    || !data
    || (data
      && (!data.organization || !data.organization.customPalette || !data.organization.customPalette.dashboardLogo))) {
    return gnowbeLogo
  }

  if (loading) return <div></div>

  return (
    <img src={data.organization.customPalette?.dashboardLogo || undefined} className="rounded-full w-full h-full object-contain bg-white border border-solid border-[#EEE8D9]" />
  )
}
