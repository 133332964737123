import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { BundleExtRefView } from './bundle/BundleExtRefView'
import { AccountHandler, CompaniesViewHandler, CompanyCourseViewHandler, CompanyUserViewHandler, CompanyViewHandler, CourseStatusViewHandler, CoursesViewHandler, ExternalDLMainGuideHandler, ExternalDLOrganizationHandler, ExternalDLPlanUpgradeHandler, GnowbeBundleViewHandler, InternalDLOrganizationHandler, OrganizationBundleViewHandler, OrganizationCourseViewHandler, OrganizationUserViewHandler, OrganizationViewHandler, PartnersViewHandler, RootHandler, SuperAdminCourseDeleteHandler, SuperAdminJobRunnerHandler, SuperAdminRedisInstaceTopicHandler, SuperAdminRedisInstanceDLQHandler, SuperAdminRedisInstanceStreamHandler, SuperAdminRedisTopicsHandler, SuperAdminUserDeleteHandler, UsersViewHandler } from './RoutesHandler'
import { page } from 'segment'
import BannersView from './notifications/banners/BannersView'
import Referrals from './organization/Referrals'
import ListOrganizations from './ListOrganizations'
import { useQuery, useSubscription } from '@apollo/client'
import { GetOrganizationRes, GetOrganizationSsoSettingsRes, GetOrganizationVars, GET_ORGANIZATION_AND_SSO_SETTINGS, GET_ORGANIZATION_LANDING_PAGE_SETTINGS, GetOrganizationLandingPageSettingsRes, GetOrganizationLandingPageSettingsVars } from 'queries/organization'
import { ORGANIZATION_SUBSCRIPTION } from 'subscriptions/organization'
import { OrganizationContextType, OrganizationProvider } from './context'
import ErrorCmp from './Error'
import { useAuthUser } from './AuthUserWrapper'
import { isManager, isSales, isSuperEditor2 } from 'lib/acl_logic'

const OrganizationsView = React.lazy(() => import('./organization/OrganizationsView'))
const LearnSalesView = React.lazy(() => import('./bundle/LearnSalesView'))
const LearnBundlesView = React.lazy(() => import('./bundle/LearnBundlesView'))
const ShopSalesView = React.lazy(() => import('./bundle/ShopSalesView'))
const CourseLibrary = React.lazy(() => import('./library/LibraryView'))
const PromoCodesView = React.lazy(() => import('./bundle/PromoCodesView'))
const EditorCoursesView = React.lazy(() => import('./course/EditorCoursesView'))
const MidServicesView = React.lazy(() => import('./services/mid_services/View'))
const ServiceMLCsView = React.lazy(() => import('./services/mlcs/View'))

const AllRoutes = () => {
  const {data: userAuthData} = useAuthUser()
  const location = useLocation()
  const pattern: RegExp = /organization\/([^\/]+)/ // search organizationId in the url
  const organizationId = location.pathname.match(pattern)
    ? location.pathname.match(pattern)![1]
    : null

  const {
    data: organizationData, loading: isLoading, error, updateQuery,
  } = useQuery<GetOrganizationRes&GetOrganizationSsoSettingsRes, GetOrganizationVars>(
    GET_ORGANIZATION_AND_SSO_SETTINGS(['with_contact', 'with_tags', 'with_settings', 'with_externalUserAttributes']),
    {
      variables: {organizationId: organizationId || ''}, // added this string becasue of the tslint error
      skip: !organizationId,
    },
  )
  const {
    loading, error: orgLandingPageSettingsError, data: organizationLandingPageSettings, refetch,
  } = useQuery<GetOrganizationLandingPageSettingsRes, GetOrganizationLandingPageSettingsVars>(
    GET_ORGANIZATION_LANDING_PAGE_SETTINGS,
    {
      variables: {
        organizationId: organizationId || '',
      },
      skip: !organizationId || (!isSuperEditor2(userAuthData?.userAuthenticated) && !isSales(userAuthData?.userAuthenticated) && !isManager(userAuthData?.userAuthenticated, organizationId)),
    },
  )

  useSubscription(
    ORGANIZATION_SUBSCRIPTION,
    {
      variables: {organizationId},
      onData: ({ data }) => {
        if (!data.error && data.data) {
          const org = data.data.organization
          updateQuery((_prev) => {
            return {
              organization: org,
              organizationSsoSettings: _prev.organizationSsoSettings,
            }
          })
        }
      },
      skip: !organizationId,
    },
  )

  React.useEffect(
    () => {
      page()
    },
    [location.pathname],
  )

  if (error) {
    return <ErrorCmp error={error} />
  }

  return (
    <Suspense fallback={null}>
       <OrganizationProvider value={
        {
          organization: organizationData?.organization || null,
          organizationLodaing: isLoading,
          organizationSsoSettings: organizationData?.organizationSsoSettings || null,
          organizationLandingPageSettings: organizationLandingPageSettings?.res || null,
        } as OrganizationContextType}
      >
        <Routes>
          <Route path="/" element={<RootHandler />} />
          <Route path="/account" element={<AccountHandler />} />
          <Route path="/organizations" element={<OrganizationsView />} />
          <Route path="/referral/:organizationId" element={<Referrals />}/>
          <Route path="/organization/:organizationId" element={<OrganizationViewHandler />}/>
          <Route path="/organization/:organizationId/company/:companyId" element={<CompanyViewHandler />} />
          <Route path="/organization/:organizationId/company/:companyId/program/:courseId"
            element={<CompanyCourseViewHandler />} />
          <Route path="/organization/:organizationId/program/:courseId" element={<OrganizationCourseViewHandler />} />
          <Route path="/organization/:organizationId/company/:companyId/users/:userId"
            element={<CompanyUserViewHandler />}/>
          <Route path="/organization/:organizationId/users/:userId" element={<OrganizationUserViewHandler />} />
          <Route path="/organization/:organizationId/bundles/:bundleId" element={<OrganizationBundleViewHandler />} />
          <Route path="/programs" element={<CoursesViewHandler />} />
          <Route path="/program/:courseId/status" element={<CourseStatusViewHandler />} />
          <Route path="/curator/programs" element={<EditorCoursesView />}/>
          <Route path="/services/mid_services" element={<MidServicesView />} />
          <Route path="/services/gnowbe_mlcs" element={<ServiceMLCsView />} />

          {/* Gnowbe internal routes*/}
          <Route path="/users" element={<UsersViewHandler />} />
          <Route path="/companies" element={<CompaniesViewHandler />} />
          <Route path="/partners" element={<PartnersViewHandler />} />
          <Route path="/learn/bundles" element={<LearnBundlesView />}/>
          <Route path="/learn/bundles/:bundleId" element={<GnowbeBundleViewHandler />} />
          <Route path="/learn/sales" element={<LearnSalesView />} />
          <Route path="/learn/promo_codes" element={<PromoCodesView />} />
          <Route path="/shop/sales" element={<ShopSalesView />} />
          <Route path="/bundle_ext_ref_gen" element={<BundleExtRefView />} />
          <Route path="/course_libraries" element={<CourseLibrary />}/>
          <Route path="/admin/job_runner" element={<SuperAdminJobRunnerHandler />} />
          <Route path="/admin/redis_streams/topics" element={<SuperAdminRedisTopicsHandler />} />
          <Route path="/admin/redis_streams/instance/:instance/topic/:name"
            element={<SuperAdminRedisInstaceTopicHandler />} />
          <Route path="/admin/redis_streams/instance/:instance/stream/:name"
            element={<SuperAdminRedisInstanceStreamHandler />} />
          <Route path="/admin/redis_streams/instance/:instance/dlq/:name"
            element={<SuperAdminRedisInstanceDLQHandler />} />
          <Route path="/admin/course_delete_confirmations" element={<SuperAdminCourseDeleteHandler />} />
          <Route path="/admin/user_delete_confirmations" element={<SuperAdminUserDeleteHandler />} />
          <Route path="/banners" element={<BannersView />} />

          {/* INTERNAL DEEP LINK */}
          <Route path="/di/organization/:organizationId" element={<InternalDLOrganizationHandler />} />

          {/* PUBLIC DEEP LINKS */}
          <Route path="/d/organization/:organizationId/upgrade" element={<ExternalDLPlanUpgradeHandler />} />
          <Route path="/d/organization/:organizationId" element={<ExternalDLOrganizationHandler />} />
          <Route path="/d/organization/:organizationId/showMainGuide" element={<ExternalDLMainGuideHandler />} />
          <Route path="*" element={<Navigate to="/welcome" replace/>} />
          <Route path="/welcome" element={<ListOrganizations />} />
        </Routes>
      </OrganizationProvider>
    </Suspense>
  )
}

export default AllRoutes
